class UUIDHelper {
  static isValidUuid(uuid) {
    // Match example: 123e4567-e89b-12d3-a456-426655440000
    const regex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gm;
    let result = regex.exec(uuid);
    if (result === null) {
      return false;
    } else {
      return result.length === 1;
    }
  }
  static generateUuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
}

export default UUIDHelper;